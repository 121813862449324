import Swiper, { Autoplay, Navigation, Pagination, Manipulation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../scss/app.scss";

const runBlockScript = (blockName, callback) => {
    // run for Front End View
    callback();
  
    // run for Edit View
    if (window.acf) {
      window.acf.addAction(`render_block_preview/type=${blockName}`, callback);
    }
  };
  
  const runGridFilters = () => {
    // Grid filters, Case Studies
    document.querySelectorAll('.js-grid-filter-btn').forEach((item) => {
      item.addEventListener('click', (event) => {
        const category = event.currentTarget.dataset.trigger;
        document.querySelectorAll('.js-grid-filter-btn').forEach((item) => {
          item.classList.remove('active');
        });
        event.currentTarget.classList.add('active');
  
        document
          .querySelectorAll('.ca-grid-case-studies .ca-infocard')
          .forEach((item) => {
            const action = category === 'all' || item.dataset.category === category ? "remove" : "add";
  
            item.classList[ action ]('hidden');
          });
      });
    });
  };
  
  
  const runTabcordion = () => {
    // Tabcordion / Tabs
    document
      .querySelectorAll(
        '.ca-tabcordion__filter-item, .ca-tabcordion__item-toggle'
      )
      .forEach((item) => {
        item.addEventListener('click', (event) => {
          const selectedTabID = item.dataset.toggle;
          const selectedTab = document.getElementById(selectedTabID);
  
          document
            .querySelectorAll('.ca-tabcordion__item')
            .forEach((accordion) => {
              accordion.classList.remove('active');
            });
  
          document
            .querySelectorAll('.ca-tabcordion__filter-item')
            .forEach((tab) => {
              tab.classList.remove('active');
            });
  
          document
            .querySelector(
              `.ca-tabcordion__filter-item[data-toggle="${selectedTabID}"]`
            )
            .classList.add('active');
  
          selectedTab.classList.add('active');
        });
      });
  };

  const SwiperWrapperForVideoBlock = (element = false, options = {}) => {
    let swiper = false;

    if (element) {
      const swiperEl = element.querySelector('.swiper');
      const nextEl = element.querySelector('.swiper-button-next') || element.querySelector('.swiper-button-next--horizontal');
      const prevEl = element.querySelector('.swiper-button-prev') || element.querySelector('.swiper-button-prev--horizontal');
      const paginationEl = element.querySelector('.ca-swiper-pagination');
      const defaultOptions = {
        modules: [Navigation, Pagination, Manipulation],
        navigation: {
          nextEl,
          prevEl
        },
        pagination: {
          el: paginationEl,
          type: 'progressbar',
        },
      }

      swiper = new Swiper(swiperEl, {...defaultOptions, ...options});
    }

    return swiper;
  }
  
  const SwiperWrapper = (elements = false, options = {}) => {
    let swiper = false;

    if (elements && elements.length > 0) {
      elements.forEach((ref) => {
        const swiperEl = ref.querySelector('.swiper');
        const nextEl = ref.querySelector('.swiper-button-next') || ref.querySelector('.swiper-button-next--horizontal');
        const prevEl = ref.querySelector('.swiper-button-prev') || ref.querySelector('.swiper-button-prev--horizontal');
        const paginationEl = ref.querySelector('.ca-swiper-pagination');
        const defaultOptions = {
          modules: [Navigation, Pagination, Manipulation],
          navigation: {
            nextEl,
            prevEl
          },
          pagination: {
            el: paginationEl,
            type: 'progressbar',
          },
        }
  
        swiper = new Swiper(swiperEl, {...defaultOptions, ...options});
      })
    }

    return swiper;
  }
  
  
  const SwiperWrapper2 = (elements = false, cb) => {
    if (elements && elements.length > 0) {
      elements.forEach((ref) => {
        const swiperEl = ref.querySelector('.swiper');
        const nextEl = ref.querySelector('.swiper-button-next');
        const prevEl = ref.querySelector('.swiper-button-prev');
        const paginationEl = ref.querySelector('.ca-swiper-pagination');
        const defaultOptions = {
          navigation: {
            nextEl,
            prevEl
          },
          pagination: {
            el: paginationEl,
            type: 'progressbar',
          },
        }

        return new Swiper(swiperEl, cb(ref, defaultOptions));
      })
    }
  }
  
  const runHeroHomepageCarousel = () => {
    const domRefs = document.querySelectorAll('.swiper-hero-home-wrapper');
  
    SwiperWrapper(domRefs, {
      loop: true,
        slidesPerView: 2.4,
        spaceBetween: 16,
        slidesOffsetBefore: 32,
        autoplay: {
          delay: 3000,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          // when window width is >= 640px
          768: {
            slidesPerView: 2.5,
            spaceBetween: 16,
            slidesOffsetBefore: 76,
          },
          autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
          },
        },
    });
  };
  
  
  const runCardPortraitGridCarousel = () => {
    const domRefs = document.querySelectorAll('.swiper-portrait-grid');
  
    SwiperWrapper(domRefs, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 24,
      centeredSlides: true,
      breakpoints: {
        768: {
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 48,
          centeredSlides: false,
        },
      },
  
    });
  };
  
  
  
  const runHomeCarousel2 = () => {
    const domRefs = document.querySelectorAll('.swiper-home-carousel-2');
    SwiperWrapper(domRefs, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 32,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    });
  };
  
  
  const runHomeCarousel1 = () => {
    const domRefs = document.querySelectorAll('.swiper-home-carousel-1');
    SwiperWrapper(domRefs, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 32,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    });
  };
  
  const runTimelineCarousel = () => {
    const domRefs = document.querySelectorAll('.swiper-carousel-timeline');
    SwiperWrapper(domRefs, {
      loop: false,
      slidesPerView: 1.1,
      spaceBetween: 12,
      breakpoints: {
        768: {
          slidesPerView: 1.14,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
      },
    });
  }
  
  
  const runCaseStudiesCarousel = () => {
    const domRefs = document.querySelectorAll('.swiper-case-studies');
  
    SwiperWrapper(domRefs, {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 24,
        centeredSlides: true,
        allowTouchMove: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 24,
            centeredSlides: false,
          },
        },
      });
  }


  const videoBlockSlideTemplate = ({ thumbnail, heading, text, type = "" }) => `
    <div class="swiper-slide">
      <div data-video-id="vid-881"  class="ca-card ca-card--fixed-info ca-card--hover-dark ca-video-block__btn ca-video-block__btn--${type}">
        <img src="${thumbnail.src}" alt="${thumbnail.alt}" class="ca-card__img" />
        <div class="ca-card__info">
          <div class="ca-card__info-header">${heading}</div>
          <div class="ca-text-body-2 mt-2">${text}</div>
        </div>
      </div>
    </div>
  `;

  const videoBlock = (container) => {
    let slides = false;
    let activeIndex = 0;

    const stageRef = container.querySelector('[data-video-block-stage]');

    if (stageRef && stageRef.hasAttribute("data-slides")) {
      slides = JSON.parse(stageRef.getAttribute("data-slides"));
    }

    if (!stageRef || !slides) {
      return;
    }


    const url = new URL(window.location.href);
    const isGoToSlide = url.searchParams.get('slide');
    const goToSlide = isGoToSlide && +isGoToSlide >= 0 && +isGoToSlide <= slides.length ? +isGoToSlide - 1 : 0;


    const videoSliderRef = container.querySelector('.swiper-video-block');
    const accordionRef = container.querySelector('.swiper-accordion-block');

    const updateStage = (index = 0) => {
      if (activeIndex === index && index > 0) return;

      activeIndex = index;
      const selectedSlide = slides[activeIndex];
      let stage = selectedSlide.stage;
      let accordion = '';

      if (selectedSlide.type === "image") {
        stage = `<img src="${selectedSlide.stage}" alt="${selectedSlide.title}" />`;
      }
    
      if (selectedSlide.accordion_title) {
        accordion = `
          <details class="ca-accordion">
            <summary class="ca-accordion__title">${selectedSlide.accordion_title}</summary>
            <div class="ca-accordion__content">${selectedSlide.accordion_text}</div>
          </details>
        `;
      }

      stageRef.innerHTML = '';
      stageRef.innerHTML = stage;
      accordionRef.innerHTML = accordion;
      url.searchParams.set('slide', activeIndex + 1);

      window.history.pushState(`slide-${activeIndex + 1}`, `Slide ${activeIndex + 1}`, url);
    }

    const videoSlider = SwiperWrapperForVideoBlock(videoSliderRef, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 12,
      breakpoints: {
        375: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
      on: {
        init: function(swiper) {
          swiper.activeIndex = activeIndex;
          updateStage(swiper.activeIndex);
          swiper.updateSlidesClasses();
        },
        resize: function(swiper) {
          // dont change active slide on resize
          if (swiper.activeIndex !== activeIndex) {
            swiper.activeIndex = activeIndex;
            swiper.updateSlidesClasses();
          }
        },
        click: function(swiper) {
          swiper.activeIndex = swiper.clickedIndex;
          updateStage(swiper.clickedIndex);
          swiper.updateSlidesClasses();
        },
        slideChangeTransitionEnd: function(swiper) {
          updateStage(swiper.activeIndex);
          swiper.updateSlidesClasses();
        }
      }
    });

    videoSlider.appendSlide(slides.map(videoBlockSlideTemplate));

    videoSlider.on("navigationNext navigationPrev", function(swiper) {
      updateStage(swiper.activeIndex);
    });

    updateStage(goToSlide);
    videoSlider.slideTo(goToSlide);
  }



  const runVideoBlockCarousel = () => {
    const videoBlockRefs = document.querySelectorAll('[data-video-block]');

    [...videoBlockRefs].forEach(videoBlock);
  };
  
  const runTogglePricingTwoPlans = () => {
    const toggleRef = document.querySelector("#pricing-toggle");
    const panelRefs = document.querySelectorAll(".cmw-pricing-two-plans-panel");
  
    if (!toggleRef || !panelRefs) {
      return;
    }
  
    toggleRef.addEventListener("change", (event) => {
      const activeItem = toggleRef.checked ? 1 : 0;
      const deActiveItem = !toggleRef.checked ? 1 : 0;
      
      panelRefs[deActiveItem].classList.remove("active");
      panelRefs[activeItem].classList.add("active");
    });
  }


  const runPostsGridLayoutFilters = () => {

    const containers = document.querySelectorAll("[data-toggle-group]");

  
    [...containers].map((container) => {

      let activeToggleTrigger = container.dataset["toggle-default-category"] ?? "";
  
      const toggleSelect = container.querySelectorAll("[data-toggle-select]");
      const toggleTriggers = container.querySelectorAll("[data-toggle-trigger]");
      const toggleTargets = container.querySelectorAll("[data-toggle-target]");
      const dataToggleSeeMore = container.querySelector("[data-toggle-see-more]");

      dataToggleSeeMore.addEventListener("click", (event) => {
        const hiddenItems = container.querySelectorAll(".ca-card-wrapper.cmw-show-md-up");
      
        event.target.remove();

        [...hiddenItems].map((item) => {
          item.classList.remove("cmw-show-md-up");
        });
      });
  
      [...toggleTriggers].map((_trigger) => {
        _trigger.addEventListener("click", (event) => {
          event.preventDefault();
          activeToggleTrigger = event.target.dataset.toggleTrigger;
  
          [...toggleTargets].forEach((toggle) => {
            const action = `${ toggle.dataset.toggleTarget === activeToggleTrigger ? "set" : "remove" }Attribute`;
  
            toggle[ action ]("data-toggle-target-active", true);
          });
  
          [...toggleTriggers].forEach((trigger) => {
            const action = `${ trigger.dataset.toggleTrigger === activeToggleTrigger ? "set" : "remove" }Attribute`;
  
            trigger[ action ]("data-toggle-trigger-active", true);
          });

          [...toggleSelect].map((select) => {
            select.value = activeToggleTrigger;
          });
        });
      });

      [...toggleSelect].map((select) => {
        select.addEventListener("change", (event) => {
          event.preventDefault();
          activeToggleTrigger = event.target.value;
          
          [...toggleTargets].forEach((toggle) => {
            const action = `${ toggle.dataset.toggleTarget === activeToggleTrigger ? "set" : "remove" }Attribute`;
  
            toggle[ action ]("data-toggle-target-active", true);
          });
  
          [...toggleTriggers].forEach((trigger) => {
            const action = `${ trigger.dataset.toggleTrigger === activeToggleTrigger ? "set" : "remove" }Attribute`;
  
            trigger[ action ]("data-toggle-trigger-active", true);
          });
        });
      });
    });
  }

  function pricingCardToggle(ref) {
    const button = ref.querySelector(".ca-price-card__toggle");
    
    button.addEventListener("click", function(event) {
      ref.classList.toggle("open");
    });
  }

  function runPricingCardToggleBlock() {
    const refs = document.querySelectorAll(".ca-price-card--toggle");

    if (!refs) {
      return;
    }

    [...refs].forEach(pricingCardToggle);
  }


  function runSecondaryNavBlock() {
    const secondaryNavRef = document.querySelector(".ca-secondary-nav");

    if (!secondaryNavRef) {
      return;
    }

    const secondaryNavLinkRefs = secondaryNavRef.querySelectorAll("[href^='#']");

    Array.from(secondaryNavLinkRefs).forEach((element) => {
        element.addEventListener("click", (event) => {
            event.preventDefault();

            const target = document.querySelector(element.hash);
            
            if (target) {
                window.scrollTo({ 
                  top: target.offsetTop - secondaryNavRef.offsetHeight,
                  left: 0,
                  behavior: "smooth"
                });
            }
        });
    })
  }

  function runCustomerQuotesCarousel() {

    const carouselRefs = document.querySelectorAll("[data-customer-quotes-carousel]");

    if (!carouselRefs) {
      return;
    }

    Array.from(carouselRefs).forEach((ref) => {
      console.log({ref})
      const playRef = ref.querySelector("[data-customer-quotes-carousel-control-play]");
      const pauseRef = ref.querySelector("[data-customer-quotes-carousel-control-pause]");

      let isPaused = false;


      const swiperRef = new Swiper(ref, {
        modules: [Pagination, Navigation, Autoplay],
        slidesPerView: 1.4,
        spaceBetween: 12,
        centeredSlides: true,
        loop: true,
        allowTouchMove: false,
        autoplay: { 
          delay: 3000,
          pauseOnMouseEnter: true
        },
        pauseOnMouseEnter: true,
        breakpoints: {
          500: {
            slidesPerView: 1.8
          },
          640: {
            slidesPerView: 2.2
          },
          768: {
            slidesPerView: 3.3
          },
          992: {
            slidesPerView: 5,
            centeredSlides: false,
            allowTouchMove: false
          }
        }
      });
      pauseRef.addEventListener("click", () => {
        swiperRef.autoplay.pause();
        pauseRef.classList.add("d-none");
        playRef.classList.remove("d-none");
      });

      playRef.addEventListener("click", () => {
        swiperRef.autoplay.start();
        pauseRef.classList.remove("d-none");
        playRef.classList.add("d-none");
      });
    });



  }
  
  
  // TODO: Clean up
  document.addEventListener('DOMContentLoaded', function (event) {
  
    // called from template, not a block
    runCaseStudiesCarousel();
  
    const blockScripts = {
      'timeline-slide': runTimelineCarousel,
      'feature-carousel': runHomeCarousel1,
      'quote-carousel-with-cards': runHomeCarousel2,
      'quote-text-carousel': runHomeCarousel2,
      'card-layout-portrait-grid': runCardPortraitGridCarousel,
      'feature-tab': runTabcordion,
      'hero-homepage': runHeroHomepageCarousel,
      'card-layouts-case-studies-card-grid': runGridFilters,
      'pricing-toggle-two-plans' : runTogglePricingTwoPlans,
      'post-layouts-cards-grid' : runPostsGridLayoutFilters,
      'video-block' : runVideoBlockCarousel,
      'pricing-card-toggle' : runPricingCardToggleBlock,
      'ces-secondary-navigation' : runSecondaryNavBlock,
      'quotes-carousel' : runCustomerQuotesCarousel
    };
    
    Object.entries(blockScripts).forEach(([blockName, callback]) => {
      runBlockScript(blockName, callback);
    })
    
    // Each menu item enter
    document.querySelectorAll('.ca-menu__btn').forEach((item) => {
      item.addEventListener('mouseenter', (event) => {
        // Remove active status from all menu buttons
        document.querySelectorAll('.ca-menu__btn').forEach((item) => {
          item.classList.remove('active');
        });
  
        // De-activate all menus
        document.querySelectorAll('.ca-dropdown-menu').forEach((menu) => {
          menu.classList.remove('active');
        });
  
        // Add active status to current menu button
        item.classList.add('active');
  
        // Activate corresponding menu —if exists
        const menuID = event.currentTarget.dataset.toggle;
        const menuToToggle = document.getElementById(menuID);
        if (menuToToggle) {
          menuToToggle.classList.add('active');
        }
      });
    });
  
    // Mirror the nav items' mouseenter behavior on focus
    document.querySelectorAll('.ca-menu__btn').forEach((item) => {
      item.addEventListener('focus', (event) => {
        // Remove active status from all menu buttons
        document.querySelectorAll('.ca-menu__btn').forEach((item) => {
          item.classList.remove('active');
        });
  
        // De-activate all menus
        document.querySelectorAll('.ca-dropdown-menu').forEach((menu) => {
          menu.classList.remove('active');
        });
  
        // Add active status to current menu button
        item.classList.add('active');
  
        // Activate corresponding menu —if exists
        const menuID = event.currentTarget.dataset.toggle;
        const menuToToggle = document.getElementById(menuID);
        if (menuToToggle) {
          menuToToggle.classList.add('active');
        }
      });
    });
  
    // Remove active state from all nav items on logo focus
    document.querySelector('#ca-primary-menu > div > a').addEventListener('focus', () => {
      // Remove active status from all menu buttons
      document.querySelectorAll('.ca-menu__btn').forEach((item) => {
        item.classList.remove('active');
      });
  
      // De-activate all menus
      document.querySelectorAll('.ca-dropdown-menu').forEach((menu) => {
        menu.classList.remove('active');
      });
    });
  
    // Mouse out on whole menu wrapper
    const navWrap = document.getElementById('ca-nav-wrap');
    if (navWrap !== null) {
      navWrap.addEventListener('mouseleave', (event) => {
        document.querySelectorAll('.ca-menu__btn').forEach((item) => {
          item.classList.remove('active');
          const menuID = event.currentTarget.dataset.toggle;
          const menuToToggle = document.getElementById(menuID);
          document.querySelectorAll('.ca-dropdown-menu').forEach((item) => {
            item.classList.remove('active');
          });
        });
      });
    }
  
    // Toggle mobile menu
    const btnToggleMenu = document.querySelector('.js-btn-toggle-menu');
    if (btnToggleMenu !== null) {
      btnToggleMenu.addEventListener('click', (event) => {
        const mobileMenu = document.querySelector('.ca-mnav-wrap');
  
        // If we're closing the main menu, re-set all opened accordions
        if (event.currentTarget.classList.contains('active')) {
          document
            .querySelectorAll('.ca-mnav__accordion')
            .forEach((accordion) => {
              accordion.classList.remove('active');
            });
        }
  
        btnToggleMenu.classList.toggle('active');
        mobileMenu.classList.toggle('active');
        document.body.classList.toggle('overflow-hidden');
      });
    }
  
    /**
     * Navigation accordion (mobile)
     * Tapping on the down arrow will open the space below to reveal links.
     * Tapping on it again will close it.
     * Tapping on another down arrow will close an area that was already opened.
     */
    document.querySelectorAll('.ca-mnav__accordion-toggle').forEach((item) => {
      item.addEventListener('click', (event) => {
        let clickedItemIsClosed =
          !event.currentTarget.parentNode.classList.contains('active');
  
        document.querySelectorAll('.ca-mnav__accordion').forEach((accordion) => {
          accordion.classList.remove('active');
        });
  
        if (clickedItemIsClosed) {
          event.currentTarget.parentNode.classList.add('active');
        }
      });
    });
  
    // Tabcordion / Accordion toggles
    // document.querySelectorAll('.ca-tabcordion__item-toggle').forEach((item) => {
    //   item.addEventListener('click', (event) => {
    //     let clickedItemIsClosed =
    //       !event.currentTarget.parentNode.classList.contains('active');
  
    //     document.querySelectorAll('.ca-tabcordion__item').forEach((accordion) => {
    //       accordion.classList.remove('active');
    //     });
  
    //     if (clickedItemIsClosed) {
    //       event.currentTarget.parentNode.classList.add('active');
    //     }
    //   });
    // });
  
    // we have repeated buttons that trigger this action
    document.querySelectorAll('.js-btn-toggle-timeline-modal').forEach((item) => {
      item.addEventListener('click', (event) => {
        var dialogEl = document.getElementById('ca-dialog-timeline');
        if (dialogEl) {
          const dialogTimeline = new A11yDialog(dialogEl);
          dialogTimeline.show();
        }
      });
    });
  });
  

/**
 * CES Reports / ToC
 */

window.addEventListener('load', () => {
  let options = {
    // Add some top margin to rootMargin, to account for navigation height.
    rootMargin: '-200px 0px 0px 0px',
  };
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const id = entry.target.getAttribute('id');
      const intersectionElement = document.querySelector(
        `.ca-ces-toc__list li a[href="#${id}"]`
      );

      if (entry.intersectionRatio > 0 || entry.isIntersecting) {
        intersectionElement.classList.add('current');

        // Check if TOC anchor is in view, scroll accordingly.
        const list = document.querySelector('.ca-ces-toc__list');
        const containerTop = list.scrollTop;
        const containerBottom = containerTop + list.clientHeight;
        const elementTop = intersectionElement.offsetTop;
        const elementBottom = elementTop + intersectionElement.clientHeight;
        
        const isInView = (elementTop >= containerTop && elementBottom <= containerBottom);
        
        if(!isInView) {
          list.scrollTo({
            top: elementTop,
            left: 0,
            behavior: 'smooth'
          });
        }

        document
          .querySelectorAll('.ca-ces-toc__list li a.behind')
          .forEach((elem) => {
            elem.classList.remove('behind');
          });
        document.querySelector('.ca-ces-toc__toggle-title').innerHTML =
          intersectionElement.innerHTML;
      } else {
        if (
          document.querySelectorAll('.ca-ces-toc__list li a.current')
            .length === 1
        ) {
          if (intersectionElement.classList.contains('current')) {
            // console.log('left behind:', intersectionElement.id);
            intersectionElement.classList.add('behind');
          }
        }
        intersectionElement.classList.remove('current');
      }
    });
  }, options);

  // Track all sections that have an `id` applied
  document.querySelectorAll('.ca-ces-report-col [id]').forEach((section) => {
    observer.observe(section);
  });
});

// Mobile events for ToC
// Ref: https://codepen.io/bramus/pen/ExaEqMJ

function toggleTOCDrawer() {
  let tocDrawer = document.getElementById('ca-ces-toc-main');
  tocDrawer.classList.toggle('active');
}

window.addEventListener(
  'load',
  (event) => {
    let btnTOC = document.querySelector('.ca-ces-toc__toggle');
    if (btnTOC) {
      btnTOC.addEventListener('click', (event) => {
        toggleTOCDrawer();
      });

      document
        .querySelector('.ca-ces-toc .ca-ces-toc__list')
        .addEventListener('click', (event) => {
          if (event.target.tagName.toUpperCase() === 'A') {
            toggleTOCDrawer();
          }
        });

      let observer;

      const options = {
        root: null,
        rootMargin: '-170px 0px 0px 0px',
      };

      observer = new IntersectionObserver(handleIntersect, options);
      const boxElement = document.querySelector('.ca-ces-toc-inline-marker');
      observer.observe(boxElement);
    }
  },
  false
);

// Add click behavior for [See more] button
window.addEventListener(
  'load',
  (event) => {
    const btnSeeMore = document.getElementById('ca-ces-see-more');
    if(btnSeeMore) {
      btnSeeMore.addEventListener('click', (event) =>{
        document.querySelectorAll('.ca-ces-speaker.d-none').forEach((item) => {
          item.classList.remove('d-none');
        })
        event.target.classList.add('d-none');
      } )
    }
  },
  false
);

function handleIntersect(entries, observer) {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      document.querySelector('.ca-ces-toc-wrap').classList.remove('pinned');
    } else {
      document.querySelector('.ca-ces-toc-wrap').classList.add('pinned');
    }
  });
}

/** 
 * CES secondary navigation events 
 * */

 window.addEventListener('load', (event) => {
   // Secondary dropdown toggle
   document.querySelectorAll('.ca-custom-dropdown').forEach((item) => {
     const toggleBtn = item.querySelector('button');
     toggleBtn.addEventListener('click', (event) => {
       // Close other active dropdowns first
       document
         .querySelectorAll('.ca-custom-dropdown')
         .forEach((customDropdown) => {
           if (customDropdown !== item) {
             customDropdown.classList.remove('active');
           }
         });
       toggleBtn.parentElement.classList.toggle('active');
     });
   });

   document.addEventListener('click', (event) => {
     const openedDropdown = document.querySelector(
       '.ca-custom-dropdown.active'
     );
     if (openedDropdown) {
       const isClickInside = openedDropdown.contains(event.target);
       if (!isClickInside) {
         document
           .querySelectorAll('.ca-custom-dropdown')
           .forEach((customDropdown) => {
             customDropdown.classList.remove('active');
           });
       }
     }
   });

   document.addEventListener('keydown', (event) =>  {
     if (event.key === 'Escape') {
       document
         .querySelectorAll('.ca-custom-dropdown')
         .forEach((customDropdown) => {
           customDropdown.classList.remove('active');
         });
     }
   });
 });

// capture PID url param and prefill mkto PID form field
document.addEventListener('DOMContentLoaded', (event) => {
  if (window.MktoForms2) {
    MktoForms2.whenReady((form) => {
      const params = new URLSearchParams(window.location.search);
      const pid = params.get('PID');

      if (pid) {
        form.vals({
          'Most_Recent_Partner_ID__c': pid
        });
      }
    });
  }
});